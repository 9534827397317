<template>
  <div class="relative rounded-lg bg-white text-navy p-6 lg:p-8 xl:p-16">
    <div class="prose prose-headings:font-normal max-w-none" ref="pixCodeInput">
      <slot />
    </div>
  </div>
  <div class="mt-8 flex justify-center">
    <button @click="copyToClipboard()" type="button" class="inline-flex gap-4 items-center bg-primary-500 text-white hover:bg-primary-600 py-2.5 px-6 text-sm font-medium text-navy transition shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-full">
      <span>Copy template</span>
    </button>
  </div>
</template>

<script setup>
import { ref,inject } from 'vue';
const Notify = inject('Notify')
const pixCodeInput = ref(null);

function copyToClipboard() {
    navigator.clipboard.writeText(pixCodeInput.value.innerText);

    Notify.show({
      title: 'Success',
      message: 'Template was successfully copied to the clipboard.',
    })
}
</script>
