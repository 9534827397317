<template>
  <div class="relative" :class="{'is-active': isActive}">
        <div
          role="tab"
          :aria-expanded="isActive"
          :aria-controls="`collapse-content-${id}`"
          :aria-describedby="`collapse-content-${id}`"
        >
        <div
          class="py-3 flex items-start outline-none select-none transition-colors leading-tight gap-4"
          tabindex="0"
          role="button"
          :id="`collapse-head-${id}`"
          :class="{
            'focusing': focusing,
            'is-active': isActive
          }"
          @focus="handleFocus"
          @blur="focusing = false"
          @keyup.space.enter.stop="toggle"
          @click="toggle"
        >
          <slot name="title">{{ title }}</slot>
          <div class="ml-auto p-1 ">
            <svg :class="{'transform rotate-90': isActive}" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 transition" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
        <transition
          name="accordion"
          @before-enter="beforeEnter"
          @enter="enter"
          @after-enter="afterEnter"
          @before-leave="beforeLeave"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div
            class="overflow-hidden transition-all duration-150 ease-in-out"
            v-show="isActive"
            role="tabpanel"
            :aria-hidden="!isActive"
            :aria-labelledby="`collapse-head-${id}`"
            :id="`collapse-content-${id}`"
          >
            <div class="pb-4">
              <slot></slot>
            </div>
          </div>
        </transition>
      </div>
    </div>
</template>
<script setup>
import { ref, computed } from 'vue'
const isActive = ref(false)
const focusing = ref(false)
const isClick = ref(false)

const toggle = () => {
      isActive.value = !isActive.value
}



const beforeEnter = (el) => {
  el.style.opacity = 0;
  el.style.height = '0'

}
const enter = (el) => {
  el.style.opacity = 1;

  el.style.height = el.scrollHeight + 'px'
}
const afterEnter = (el) => {
  el.style.height = 'auto';
  //  const emit_event = context.listeners['after-enter']
  //  emit_event()
}
const beforeLeave = (el) =>{
  el.style.opacity = 1;

  el.style.height = el.scrollHeight + 'px'
}
const leave = (el) => {
  el.style.opacity = 0;

  el.style.height = '0'
}
const afterLeave = (el) => {
  //  const emit_event = context.listeners['after-leave']
  //  emit_event()
}

const handleFocus = () => {
  setTimeout(() => {
    if (!isClick.value) {
      focusing.value = true
    } else {
      isClick.value = false
    }
  }, 50)
}

const id = computed(() => {
    return generateId()
})

const generateId = () => {
      var d = new Date().getTime()
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now() // use high-precision timer if available
      }
      var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return newGuid
}

</script>
