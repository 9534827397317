<template>
  <div class="relative">
    <div v-if="arrows" class="mb-4">
      <button class="hover:text-primary-500 py-2" @click="scrollPrev" type="button" aria-label="Previous">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
      </button>
      <button class="hover:text-primary-500 py-2" @click="scrollNext" type="button" aria-label="Next">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>

        <div class="hidden lg:flex justify-center  mb-8" v-if="slides.length > 0">
          <div class="flex rounded-full overflow-hidden bg-primary-500">
            <span
              class="py-2.5 px-5 relative cursor-pointer text-white"
              v-for="(slide, n) in slides"
              @click="scrollTo(n)"
              :class="{ 'bg-primary-700': selectedIndex === n }"
              :key="n"
            >
               <span class="">{{ slide.dataset.title }}</span>
            </span>
          </div>
        </div>

      <div class="embla text_image_slider" ref="emblaNode">
        <div class="embla__container flex gap-x-6 lg:gap-x-24">
          <slot />
        </div>
      </div>

    <div v-if="pagination && scrollSnaps.length > 1" class="flex justify-center items-center gap-2 h-6 mt-4">
      <span
        class="rounded w-2 h-2 cursor-pointer transition-all"
        v-for="(dot, index) in scrollSnaps"
        @click="scrollTo(index)"
        :class="{ 'bg-white w-6': selectedIndex === index,  'bg-white/25': selectedIndex !== index }"
        :key="index"
      ></span>
    </div>
  </div>
</template>

<script>
import { watchEffect, ref, computed } from "vue";
import emblaCarouselVue from "embla-carousel-vue";
import ClassNames from "embla-carousel-class-names"

export default {
  name: 'TextImageSlider',
  props: {
    tagline: {
      type: String,
      required: false
    },
    pagination: {
      type: Boolean,
      required: false
    },
    arrows: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    const [emblaNode, emblaApi] = emblaCarouselVue({
      loop: false,
      align: 'start',
      containScroll: 'keepSnaps',
    },[ClassNames()]);
    let selectedIndex = ref(0);
    let scrollSnaps = ref([]);

    const scrollNext = () => emblaApi.value?.scrollNext();
    const scrollPrev = () => emblaApi.value?.scrollPrev();
    const scrollTo = (index) => emblaApi.value?.scrollTo(index);

    const slides = computed(() => {
      return emblaApi.value?.slideNodes() || []
    });

    const onInit = () => {
      scrollSnaps.value = emblaApi.value.scrollSnapList() || []
    };

    const onSelect = () => {
      if (!emblaApi.value) return;
      selectedIndex.value = emblaApi.value.selectedScrollSnap();
    };

    watchEffect(() => {
      if (!emblaApi.value) return;
      emblaApi.value.on("select", onSelect);
      emblaApi.value.on('reInit', onInit);
      onSelect();
      onInit();
    })

    return {
      emblaNode,
      emblaApi,
      scrollSnaps,
      slides,
      scrollNext,
      scrollPrev,
      scrollTo,
      selectedIndex,
    };
  },
};
</script>

<style>
.text_image_slider .embla__slide {
  opacity: 0.2;
  transition: opacity 0.25s;
  pointer-events: none;
}

.text_image_slider .embla__slide.is-in-view {
  opacity: 1;
  pointer-events: auto;
}
</style>
