<template>
  <div :class="control.class">
    <Label
      v-if="control.label"
      :text="control.label"
      :for="control.uuid"
      :required="control.rules && control.rules.split('|').indexOf('required') != -1"
    />
      <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
        <div v-for="option in control.options" :key="option.value">
          <label

          class="relative flex md:flex-col items-center gap-4 md:gap-2 border border-white/10 bg-white/5 p-4 lg:p-8 rounded cursor-pointer hover:text-white hover:ring-2 hover:ring-white transition duration-200 ease-in-out"
          :class="{ 'ring-2 ring-primary-500' :  value  === option.value}"
          >

            <component
            :class="{'text-primary': value  === option.value}"
            :is="option.icon" class="md:mx-auto size-12 md:size-24 flex-grow-0"
            />

            <input
              v-model="value"
              type="radio"
              :name="control.name"
              :value="option.value"
              class="hidden h-5 w-5 text-primary-500 border-gray-300 transition duration-200 ease-in-out focus:ring-offset-0 focus:ring-2 focus:ring-gray-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-gray-500"
            />
            <span>{{ option.label }}</span>
            <!-- Not Checked: "invisible" -->
            <div  v-if="option.value === value" class="absolute top-2 right-2 md:top-4 md:right-4 bg-primary-500 rounded-full h-6 w-6 p-1">
              <svg class="h-4 w-4 text-navy" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round"><path d="M20 6 9 17l-5-5"/></svg>
            </div>
          </label>
          <Note v-if="option.note">{{ option.note }}</Note>
        </div>
      </div>
      <Error v-if="errorMessage">{{ errorMessage }}</Error>
      <Note v-if="control.note">{{ control.note }}</Note>
    </div>
</template>
<script setup>
  import { useField } from 'vee-validate'
  const props = defineProps({
    control: Object
  })
  const { value, errorMessage } = useField(
    props.control.name,
    props.control.rules,
    {
      label: props.control.label
    }
  )
</script>
