<template>
  <div class="flex flex-col gap-4 text-white">
    <ShareNetwork
      v-for="network in networks"
      :network="network.network"
      :key="network.network"
      :url="url"
      :title="title"
      :description="description"
      :hashtags="sharing.hashtags"
      :twitterUser="sharing.twitterUser"
      class="inline-flex w-4 h-4 transition-colors hover:text-primary-500"
      tag="button"
      :aria-label="network.network"
    >
      <component :is="network.icon" class="w-4 h-4" />
    </ShareNetwork>
  </div>
</template>

<script>

export default {
  name: 'SocialShare',
  props: {
    url: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      sharing: {
        hashtags: 'weev',
        twitterUser: 'weev'
      },
      networks: [
        { network: 'linkedin', name: 'LinkedIn', icon: 'icon-linkedin', color: '#007bb5' },
        { network: 'twitter', name: 'Twitter', icon: 'icon-twitter', color: '#1da1f2' },
        { network: 'facebook', name: 'Facebook', icon: 'icon-facebook', color: '#1877f2' },
        { network: 'pinterest', name: 'Pinterest', icon: 'icon-pinterest', color: '#bd081c' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'icon-whatsapp', color: '#25d366' },
        { network: 'messenger', name: 'Messenger', icon: 'icon-messenger', color: '#0084ff' },
        { network: 'skype', name: 'Skype', icon: 'icon-skype', color: '#00aff0' },
        { network: 'sms', name: 'SMS', icon: 'icon-sms', color: '#333333' },
      ]
    }
  }
}
</script>
