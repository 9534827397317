import { createApp } from 'vue'
import Dialog from './Dialog.vue'

const plugin = {
    install(app, options) {

    app.config.globalProperties.$dialog = {
      alert: (props) => {
        const instance = createApp(Dialog, {
          type: 'alert',
          ...props
        }).mount(document.createElement('div'))
        // instance.type = 'alert'
        return instance.show()
      //  instance.instance = instance
      },
      confirm: (props) => {
        const instance = createApp(Dialog, {
          type: 'confirm',
          ...props
        }).mount(document.createElement('div'))
        // instance.type = 'alert'
        return instance.show()
      //  instance.instance = instance
      }
    }

    app.provide('Dialog', app.config.globalProperties.$dialog)
  }
}

export default plugin;
