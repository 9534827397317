// https://stackoverflow.com/a/3969760
export default class Timer {
    constructor(callback, duration) {
      this.startedAt = Date.now()
      this.callback = callback
      this.duration = duration

      this.timer = setTimeout(callback, duration)
    }

    pause() {
      this.stop()
      this.duration -= Date.now() - this.startedAt
    }

    resume() {
      this.stop()
      this.startedAt = Date.now()
      this.timer = setTimeout(this.callback, this.duration)
    }

    stop() {
      clearTimeout(this.timer)
    }

  }
