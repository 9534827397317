<template>
  <Transition
      :enter-from-class="classes.enterClass"
      :enter-active-class="classes.enterActiveClass"
      :enter-to-class="classes.enterToClass"
      :leave-from-class="classes.leaveClass"
      :leave-active-class="classes.leaveActiveClass"
      :leave-to-class="classes.leaveToClass"
    >
    <div v-if="show === 'true'">
      <div class="mx-auto max-w-screen-2xl w-full lg:px-6 relative lg:z-30 print:hidden">
      <div class="text-sm lg:rounded-lg flex flex-col sm:flex-row overflow-hidden relative justify-between lg:mt-4 bg-[#0a162e] overflow-hidden">
        <div class="py-3 px-5 flex flex-wrap gap-x-4 gap-y-2">
          <span>Find and connect to our chargers seamlessly.</span>
          <a href="/app" @click="hide" class="cursor-pointer font-bold underline inline-block underline-offset-4 hover:underline-offset-2">Download App</a></div>
          <button
              @click="hide"
              class="absolute top-2 right-2 sm:relative sm:top-0 sm:right-0 sm:mr-4"
              type="button"
              aria-label="Close banner"
            >
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-30">
              <path d="m7.757 7.757 8.486 8.486M7.757 16.243l8.486-8.486" stroke="currentColor" stroke-width="1.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script setup>
import { ref } from "vue"
const show = ref(sessionStorage.getItem("banner") || "true")
const classes = ref({
    enterClass: 'opacity-0',
    enterActiveClass: 'transition-all transform ease-in-out duration-300',
    enterToClass: 'opacity-100',
    leaveClass: 'opacity-100',
    leaveActiveClass: 'transition-all transform ease-in-out duration-300',
    leaveToClass: 'opacity-0'
})

const hide = () => {
  show.value = "false"
  sessionStorage.setItem("banner", "false")
}


</script>
