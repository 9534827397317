<template>
    <div
        class="relative w-full bg-white rounded-lg group"
        :class="{
            '': color === 'pink',
            'bg-gradient-to-br from-white to-secondary-500': color === 'blue',
            '': color === 'purple',
        }"
    >
        <slot />
        <Transition
            enter-from-class="opacity-0"
            enter-active-class="transition ease-in-out duration-300"
            enter-to-class="opacity-100"
            leave-from-class="opacity-100"
            leave-active-class="transition ease-in-out duration-300"
            leave-to-class="opacity-0"
        >
            <div
                v-show="open"
                class="absolute top-0 p-8 bg-white text-navy bg-gradient-to-br rounded-lg w-full min-h-full flex flex-col gap-8 flex-shrink-0"
                :class="{
                    'from-primary-500': color === 'pink',
                    'from-secondary-500': color === 'blue',
                    'from-purple-500': color === 'purple',
                }"
            >
                <slot name="info" />
            </div>
        </Transition>
        <button
            @click="open = !open"
            type="button"
            class="absolute rounded-full p-1 top-4 right-4 flex items-center"
            :class="{
                'bg-primary-500 text-white': !open && color === 'pink',
                'text-primary-500 bg-white': open && color === 'pink',
                'bg-secondary-500 text-white': !open && color === 'blue',
                'text-secondary-500 bg-white': open && color === 'blue',
                'bg-purple-500 text-white': !open && color === 'purple',
                'text-purple-500 bg-white': open && color === 'purple',
            }"
        >
            <span class="block px-3 text-sm">
                {{ open ? "Close" : "More info" }}
            </span>
            <Minus v-if="open" class="w-6 h-6" />
            <Plus v-else class="w-6 h-6" />
        </button>
    </div>
</template>
<script setup>
import { ref } from "vue";
import { Plus, Minus } from "lucide-vue-next";
const open = ref(false);

const props = defineProps({
    color: {
        type: String,
        required: false,
        default: "pink",
    },
});
</script>
