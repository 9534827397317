<template>
  <form @submit="onSubmit">
    <div class="w-full bg-gray-800 h-1 rounded-lg overflow-hidden mb-4">
      <div
        class="bg-primary-500 h-1 transition-all duration-300"
        :style="{ 'width': `${((currentStepIdx)/props.validationSchema.length)*100}%` }"
      ></div>
    </div>

    <div class="flex justify-end gap-4">
      <a href="#" class="text-sm inline-flex items-center gap-2">
        <span>Need our help</span>
        <span class="p-2 block bg-primary-500 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4"><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>
        </span>
      </a>
      <a href="#" class="text-sm inline-flex items-center gap-2">
        <span>Request a call</span>
        <span class="p-2 block bg-primary-500 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/><path d="M14.05 2a9 9 0 0 1 8 7.94"/><path d="M14.05 6A5 5 0 0 1 18 10"/></svg>
        </span>
      </a>
    </div>


    <slot :values="values" />


      <div class="flex gap-4 mt-16">
        <button
          v-if="hasPrevious"
          type="button"
          @click="goToPrev"
          class="border border-gray-800 py-2.5 px-6 font-medium text-sm disabled:bg-transparent rounded-full"
        >
          Back
        </button>

        <button
          v-if="hasPrevious"
          class="bg-primary-500 border border-primary-500 disabled:bg-transparent rounded-full hover:bg-primary-500 hover:text-white py-2.5 px-6 font-medium text-sm transition shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-full"
          type="submit"
        >
          {{ isLastStep ? 'Submit' : 'Continue' }}
        </button>

        <button
          v-if="!hasPrevious"
          class="bg-primary-500 border border-primary-500 disabled:bg-transparent rounded-full hover:bg-primary-500 hover:text-white py-2.5 px-6 font-medium text-sm transition shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded-full"
          type="submit"
        >
          Start now
        </button>
      </div>


      <div v-if="currentStepIdx === 0" class="mt-16">

        <div class="uppercase text-sm font-medium tracking-wide text-primary-400">
          Grant Information
        </div>
        <div class="grid lg:grid-cols-2 gap-4 lg:gap-16 mt-4">
          <div>
            <p class="text-lg md:text-xl lg:text-2xl font-medium max-w-2xl mb-4">
              1. Electric vehicle infrastructure grant for staff and fleets
            </p>
            <div class="prose lg:prose-lg max-w-3xl text-white/75">
              <p>The EV infrastructure grant for staff and fleets is only available to small and medium-sized businesses. The EV infrastructure grant helps out with any costs to do with building and installation work that&rsquo;s needed to install multiple chargepoint sockets, such as the installation of wiring and posts.</p>
              <p>With this grant, you can get:</p>
              <ul>
              	<li>75% of the cost of the work paid for (up to a max of &pound;15,000)</li>
              	<li>Up to &pound;350 per chargepoint socket installed</li>
              	<li>Up to &pound;500 per parking space enabled with supporting infrastructure</li>
              	<li>Up to five grants across five different sites</li>
              </ul>
            </div>
          </div>
          <div>
            <p class="text-lg md:text-xl lg:text-2xl font-medium max-w-2xl mb-4">
              2. The Workplace charging scheme grant
            </p>
            <div class="prose lg:prose-lg max-w-3xl text-white/75">

              <p>This grant can also be used at the same time as the EV infrastructure grant for staff and fleets. <strong>Both grants can be used for the same site, but never the same chargepoints.</strong></p>
              <p>The workplace charging scheme includes:</p>
              <ul>
              	<li>Cover of up to 75% of the total costs of purchasing and installation of chargepoints</li>
              	<li>Up to &pound;350 per socket</li>
              	<li>Up to 40 sockets across all sites per applicant</li>
              </ul>
              <p>You can find more information about the WCS on the Government website.</p>
            </div>
          </div>
        </div>

      </div>




    <div class=" mt-16">
      <hr class="mb-16">
      <pre>{{ values }}</pre>
    </div>
  </form>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { ref, computed, provide } from 'vue';

const props = defineProps({
  validationSchema: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['submit']);
const currentStepIdx = ref(0);

// Injects the starting step, child <form-steps> will use this to generate their ids
const stepCounter = ref(0);
provide('STEP_COUNTER', stepCounter);

// Inject the live ref of the current index to child components
// will be used to toggle each form-step visibility
provide('CURRENT_STEP_INDEX', currentStepIdx);

// if this is the last step
const isLastStep = computed(() => {
  return currentStepIdx.value === stepCounter.value - 1;
});

// If the `previous` button should appear
const hasPrevious = computed(() => {
  return currentStepIdx.value > 0;
});

// extracts the indivdual step schema
const currentSchema = computed(() => {
  return props.validationSchema[currentStepIdx.value];
});

const { values, handleSubmit } = useForm({
  // vee-validate will be aware of computed schema changes
  validationSchema: currentSchema,
  // turn this on so each step values won't get removed when you move back or to the next step
  keepValuesOnUnmount: true,
});

// We are using the "submit" handler to progress to next steps
// and to submit the form if its the last step
const onSubmit = handleSubmit((values) => {
  if (!isLastStep.value) {
    currentStepIdx.value++;
    return;
  }

  // Let the parent know the form was filled across all steps
  emit('submit', values);
});

function goToPrev() {
  if (currentStepIdx.value === 0) {
    return;
  }

  currentStepIdx.value--;
}
</script>
