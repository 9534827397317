<template>
  <div :id="href" v-show="isActive">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Tab',
  componentName: 'Tab',
  props: {
    count: {
      type: Number,
      required: false
    },
    link: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    href () {
      return this.name.toLowerCase().replace(/ /g, '-')
    }
  },
  created() {
    this.$parent.tabs.push(this);
  },
  mounted () {
    this.isActive = this.selected
  }
}
</script>
