<template>
  <div :class="control.class">
    <label class="label" v-if="control.label" :for="control.id">{{ control.label }}</label>
    <div>
      <label v-for="option in control.options" :key="option.value" class="inline-flex items-center mr-6">
        <input
          type="checkbox"
          :name="control.name"
          :value="option.value"
          @change="changed"
          class="h-6 w-6 text-gray-900 border-gray-200 transition duration-200 ease-in-out focus:ring-offset-0 focus:ring-2 focus:ring-gray-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-gray-500"
        />
        <span class="ml-2">{{ option.label }}</span>
      </label>
    </div>
    <p class="text-xs text-gray-500 mt-2" v-if="control.note">{{ control.note }}</p>
  </div>
</template>
<script>

export default {
  name: 'CheckboxControl',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    control: {
      type: Object,
      required: true
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
