<template>
    <div>
        <div v-if="arrows && scrollSnaps.length > 1" class="hidden lg:block">
            <button
                aria-label="Previous"
                class="absolute left-6 top-1/2 -translate-y-1/2 p-6 z-10"
                type="button"
                @click="scrollPrev"
            >
                <icon-arrow
                    class="w-12 h-12 stroke-1 text-white rotate-180 hover:text-primary-500 transition"
                ></icon-arrow>
            </button>
            <button
                aria-label="Next"
                class="absolute right-6 top-1/2 -translate-y-1/2 p-6 z-10"
                type="button"
                @click="scrollNext"
            >
                <icon-arrow
                    class="w-12 h-12 stroke-1 text-white hover:text-primary-500 transition"
                ></icon-arrow>
            </button>
        </div>
        <div class="relative">
            <div
                v-if="progress"
                class="bg-[#9AC2C5]/10 h-1 relative mb-8 overflow-hidden"
            >
                <div
                    class="bg-[#9AC2C5] h-full -left-full w-full absolute"
                    :style="{
                        transform: `translate3d(${scrollProgress}%,0px,0px)`,
                    }"
                ></div>
            </div>

            <div class="embla -mx-2 h-full embla__general" ref="emblaNode">
                <div class="embla__container flex h-full">
                    <slot></slot>
                </div>
            </div>
            <div
                v-if="counter && scrollSnaps.length > 1"
                class="opacity-50 text-right text-sm"
            >
                {{ selectedIndex + 1 }} / {{ slides }}
            </div>

            <slot name="before_pagination" />

            <div
                v-if="pagination && scrollSnaps.length > 1"
                class="flex justify-center items-center gap-2 h-6 mt-4"
            >
                <span
                    class="rounded w-2 h-2 cursor-pointer transition-all"
                    v-for="(dot, index) in scrollSnaps"
                    @click="scrollTo(index)"
                    :class="{
                        'bg-white w-6': selectedIndex === index,
                        'bg-white/25': selectedIndex !== index,
                    }"
                    :key="index"
                ></span>
            </div>
        </div>
    </div>
</template>
<script>
import { watchEffect, ref } from "vue";
import Autoplay from "embla-carousel-autoplay";
import emblaCarouselVue from "embla-carousel-vue";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
// import ClassNames from "embla-carousel-class-names";
export default {
    name: "GeneralSlider",
    props: {
        pagination: {
            type: Boolean,
            required: false,
        },
        progress: {
            type: Boolean,
            required: false,
        },
        counter: {
            type: Boolean,
            required: false,
        },
        arrows: {
            type: Boolean,
            required: false,
        },
        autoplay: {
            type: Boolean,
            required: false,
        },
        loop: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    setup(props) {
        const [emblaNode, emblaApi] = emblaCarouselVue(
            {
                loop: props.loop,
                align: "start",
                containScroll: "trimSnaps",
            },
            [WheelGesturesPlugin(), Autoplay()],
        );

        let scrollProgress = ref(0);
        let selectedIndex = ref(0);
        let scrollSnaps = ref([]);
        let slides = ref(0);

        const scrollNext = () => emblaApi.value.scrollNext();
        const scrollPrev = () => emblaApi.value.scrollPrev();
        const scrollTo = (index) => emblaApi.value.scrollTo(index);

        const onInit = () => {
            scrollSnaps.value = emblaApi.value.scrollSnapList() || [];
            const slideNodes = emblaApi.value.slideNodes() || [];
            slides.value = slideNodes.length;
            if (!props.autoplay) emblaApi.value.plugins().autoplay.stop();
        };

        const onSelect = () => {
            if (!emblaApi.value) return;
            selectedIndex.value = emblaApi.value.selectedScrollSnap();
        };

        const onScroll = () => {
            if (!emblaApi.value) return;
            const prog = Math.max(
                0,
                Math.min(1, emblaApi.value.scrollProgress()),
            );
            scrollProgress.value = prog * 100;
        };

        watchEffect(() => {
            if (!emblaApi.value) return;
            emblaApi.value.on("select", onSelect);
            emblaApi.value.on("reInit", onInit);
            emblaApi.value.on("scroll", onScroll);
            onSelect();
            onInit();
        });

        return {
            emblaNode,
            emblaApi,
            slides,
            scrollSnaps,
            scrollNext,
            scrollPrev,
            scrollTo,
            selectedIndex,
            scrollProgress,
        };
    },
};
</script>
