<template>
  <div v-if="tabs.length > 1" class="w-full flex justify-center">


    <ul v-if="variant === 'blue'" class="flex rounded-full overflow-hidden bg-secondary-500 mx-auto">
        <li v-for="(tab, index) in tabs" :key="index">
          <a
            v-if="tab.link"
            :href="tab.link"
            class="py-2.5 px-5 min-w-32 lg:min-w-44 text-center relative cursor-pointer text-white hover:bg-secondary-600 block transition-colors"
          >
            <span class=" font-medium whitespace-nowrap">{{ tab.name }}</span>
          </a>
          <span
            v-else
            :class="{
              'bg-secondary-600': tab.isActive,
              'bg-secondary-500': !tab.isActive
              }"
            class="py-2.5 px-5 min-w-32 lg:min-w-44 text-center relative cursor-pointer text-white hover:bg-secondary-700 block transition-colors"
            @click="selectTab(tab)"
          >
            <span class="font-medium  whitespace-nowrap">{{ tab.name }}</span>
          </span>

        </li>
    </ul>

    <ul v-else class="flex flex-wrap gap-2 lg:gap-8 w-full">
        <li v-for="(tab, index) in tabs" :key="index" class="flex-grow">
          <a
            v-if="tab.link"
            :href="tab.link"
            class="flex transition-colors rounded-lg hover:bg-primary-700/50 items-center justify-center"
          >
            <span class="cursor-pointer font-medium uppercase py-2.5 px-5 whitespace-nowrap">{{ tab.name }}</span>
          </a>
          <span
            v-else
            :class="{
              'bg-primary-500': tab.isActive,
              'bg-primary-700/25': !tab.isActive
              }"
            class="flex transition-colors rounded-lg hover:bg-primary-700/50 items-center justify-center"
            @click="selectTab(tab)"
          >
            <span class="cursor-pointer font-medium uppercase py-2.5 px-5 whitespace-nowrap">{{ tab.name }}</span>
          </span>

        </li>
    </ul>


  </div>
  <div :class="{'mt-4 lg:mt-8': tabs.length > 1}">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Tabs',
  data () {
    return {
      tabs: []
    }
  },
  props: {
    variant: {
      type: String,
      required: false
    }
  },
  methods: {
    selectTab (selectedTab) {
      this.tabs.forEach((tab) => {
        tab.isActive = (tab.name === selectedTab.name)
      })
      this.$emit('changed', { tab: selectedTab.name })
    }
  }
}
</script>
