<template>
  <Field
    v-slot="{ field, errors, value }"
    :label="control.label"
    :name="control.name"
    :rules="control.rules"
  >
    <div :class="control.class">
      <label
        v-if="control.label"
        :for="control.uuid"
        class="label"
        :class="{'required': control.rules && control.rules.split('|').indexOf('required') != -1 }"
      >
        {{ control.label }}
      </label>
      <div class="relative">
        <label :for="control.uuid" class="select-none flex items-center px-4 border-r border-white/10 absolute inset-y-px left-0">{{ control.currency || '£' }}</label>
        <input
          v-bind="field"
          :id="control.uuid"
          type="number"
          step="1.00"
          :min="control.min"
          :max="control.max"
          :name="control.name"
          :readonly="control.readonly"
          :placeholder="control.placeholder"
          :aria-label="control.placeholder"
          :disabled="control.disabled"
          class="pl-14 appearance-none appearance-none placeholder-gray-400 py-3 px-4 border border-white/10 bg-white/5 rounded-lg block transition duration-200 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-primary-500 w-full"
          @change="changed"
        />
      </div>
      <ErrorMessage
        :name="control.name"
        class="block text-primary-600 text-sm mt-2"
      />
      <p
        v-if="control.note"
        class="text-sm text-gray-500 mt-2"
      >{{ control.note }}</p>
    </div>
  </Field>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate'
export default {
  name: 'PriceControl',
  emits: ['update:modelValue'],
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    control: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    function changed(e) {
      emit('update:modelValue', e.currentTarget.value);
    }

    return {
      changed
    }
  }
}
</script>
<style>

  input[type=number].appearance-none::-webkit-inner-spin-button,
  input[type=number].appearance-none::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number].appearance-none {
    -moz-appearance:textfield;
  }
</style>
