<template>
  <transition
    enter-from-class="opacity-0"
    enter-active-class="transition-all transform duration-300"
    enter-to-class="opacity-100"
    leave-from-class="opacity-100"
    leave-active-class="transition-all transform duration-300"
    leave-to-class="opacity-0"
  >
    <div v-show="isOpen" class="z-10 h-screen w-screen inset-0 fixed bg-navy-900 bg-opacity-75 lg:bg-opacity-25 backdrop-blur-sm"></div>
  </transition>
  <nav
    class="top-0 z-20 w-full navigation sticky w-full"
    :class="{'bg-navy-900 transition-colors duration-300': isOpen }"
    @mouseleave="hide"
  >
    <div
      class="transition"
      :class="{
        'bg-navy-900/75 backdrop-blur-xl shadow-2xl': !atTopOfPage,
        'text-white': atTopOfPage
      }"
    >
      <slot
        :active="active"
        :setActive="setActive"
        :isOpen="isOpen"
        :toggle="toggle"
        :open="open"
        :hide="hide"

      />
    </div>
  </nav>
</template>
<script setup>
  import {
    onMounted,
    onBeforeMount,
    onUnmounted,
    ref
  } from 'vue';
  const active = ref(null)
  const isOpen = ref(false)
  const fired = ref(false)

  const open = () => {
    isOpen.value = true
  }
  const toggle = () => {
    isOpen.value = !isOpen.value
  }
  const hide = () => {
    isOpen.value = false
    active.value = null
  }

  const setActive = (item) => {
    active.value = item
  }
  const atTopOfPage = ref(true);
  // the function to call when the user scrolls, added as a method
  const handleScroll = (() => {
        if(fired.value === false){
          hide()
          fired.value = true
        }

        if(window.pageYOffset>0){
            // user is scrolled
            document.getElementsByTagName('html')[0].classList.add('scrolled')
            if(atTopOfPage.value) atTopOfPage.value = false
        }else{
            // user is at top of page
            document.getElementsByTagName('html')[0].classList.remove('scrolled')
            if(!atTopOfPage.value) atTopOfPage.value = true
        }
    })

  // a beforeMount call to add a listener to the window
  onBeforeMount(() => {
      window.addEventListener('scroll', handleScroll);
  })

  onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
  })
</script>
