<template>
    <div :class="control.class">
      <label
        v-if="control.label"
        :for="control.uuid"
        class="label"
        :class="{'required': control.rules && control.rules.split('|').indexOf('required') != -1 }"
      >
        {{ control.label }}
      </label>
      <div class="relative">
        <select
            v-model="value"
            :id="control.uuid"
            :multiple="control.multiple"
            :disabled="control.disabled"
            @change="changed"
            class="appearance-none placeholder-gray-400 py-3 px-4 border border-white/10 bg-white/5 rounded-lg block transition duration-200 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-primary-500 w-full"
          >
          <option v-if="control.placeholder" disabled>{{ control.placeholder }}</option>
          <option
            v-for="option in control.options"
            :key="option.value"
            :value="option.value"
          >{{ option.label }}</option>
        </select>
      </div>
      <Error v-if="errorMessage">{{ errorMessage }}</Error>
      <Note v-if="control.note">{{ control.note }}</Note>
    </div>
</template>
<script setup>
import { useField } from 'vee-validate'

const props = defineProps({
  control: Object
})
const { value, errorMessage } = useField(
  props.control.name,
  props.control.rules,
  {
    label: props.control.label
  }
)
</script>
<style>
  select option{
    color: #000;
  }
</style>
