<template>
  <div v-if="fields" class="grid grid-cols-6 gap-x-8 gap-y-4">
    <template v-for="control in fields">
      <Component
        :key="control.name"
        :is="control.component"
        v-if="control.if ? (modelValue[control.if.field] === control.if.value || false) : true"
        v-model="modelValue[control.name]"
        :control="{ uuid : generateUUID(), ...control}"
      />
    </template>
  </div>
</template>
<script setup>
  const props = defineProps({
    modelValue: {
      type: [Object, Array],
      default: () => {},
      required: true
    },
    fields: {
      type: Array,
      required: true
    }
  })

  const generateUUID = (() => {
    let d = new Date().getTime()
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now()
    }
    const newGuid = 'xxxxxxxx-xxxx-1xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return newGuid
  })

</script>
