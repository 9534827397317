<template>
  <Field
    v-slot="{ field, errors, value }"
    :label="control.label"
    :name="control.name"
    :rules="control.rules"
  >
    <div :class="control.class">
      <label
        v-if="control.label"
        :for="control.uuid"
        class="label"
        :class="{'required': control.rules && control.rules.split('|').indexOf('required') != -1 }"
      >
        {{ control.label }}
      </label>
    <textarea
      v-bind="field"
      :id="control.uuid"
      ref="textarea"
      :name="control.name"
      :placeholder="control.placeholder"
      :aria-label="control.placeholder"
      :disabled="control.disabled"
      :rows="control.rows"
      class="appearance-none placeholder-gray-400 py-3 px-4 border border-white/10 bg-white/5 rounded-lg block transition duration-200 ease-in-out focus:ring-2 focus:ring-gray-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-gray-500 w-full"
      @change="changed"
      @input="resizeTextarea"
    ></textarea>
    <ErrorMessage
      :name="control.name"
      class="block text-primary-600 text-sm mt-2"
    />
    <p
      v-if="control.note"
      class="text-sm text-gray-500 mt-2"
    >{{ control.note }}</p>
  </div>
</Field>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate'
import { defineComponent, nextTick } from "vue"

export default defineComponent({
  name: 'TextareaControl',
  emits: ['update:modelValue'],
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: null
    },
    control: {
      type: Object,
      required: true
    }
  },
  mounted () {
    nextTick(() => {
      this.$refs.textarea.setAttribute('style', 'resize: none;min-height:50px;height:' + (this.$refs.textarea.scrollHeight) + 'px;overflow-y:hidden;')
    })
  },
  methods: {
    resizeTextarea (event) {
      event.target.style.height = 'auto'
      event.target.style.height = (event.target.scrollHeight) + 'px'
    }
  },
  setup(props, { emit }) {
    function changed(e) {
      emit('update:modelValue', e.currentTarget.value);
    }
    return {
      changed
    }
  }
})
</script>
