<template>
    <div class="relative h-12 text-primary-500">
        <svg
            x="0px"
            y="0px"
            class="wave block absolute inset-x-0 w-full top-24 h-px overflow-visible z-0"
            ref="svg"
            fill="none"
            stroke="currentColor"
        >
            <defs>
                <linearGradient id="gradient">
                    <stop offset="0" stop-color="#00b6ed" />
                    <stop offset="0" stop-color="#0baee9" />
                    <stop offset="0.25" stop-color="#13a8e6" />
                    <stop offset="0.27" stop-color="#1ea0e2" />
                    <stop offset="0.34" stop-color="#527bce" />
                    <stop offset="0.43" stop-color="#805abc" />
                    <stop offset="0.51" stop-color="#a73ead" />
                    <stop offset="0.6" stop-color="#c728a1" />
                    <stop offset="0.69" stop-color="#e01698" />
                    <stop offset="0.78" stop-color="#f10a91" />
                    <stop offset="0.88" stop-color="#fc028d" />
                    <stop offset="1" stop-color="#ff008c" />
                </linearGradient>
            </defs>
            <g>
                <polyline
                    vector-effect="non-scaling-stroke"
                    stroke-dasharray="0 10 0"
                    class="animated-dashed-lines"
                />
                <polyline
                    vector-effect="non-scaling-stroke"
                    stroke-dasharray="0 10 0"
                    stroke-opacity="0.75"
                    stroke-width="1"
                    class="animated-dashed-lines"
                />
            </g>
            <g>
                <polyline
                    v-for="index in 6"
                    :key="index"
                    vector-effect="non-scaling-stroke"
                    stroke-opacity="0.3"
                    stroke-width="1"
                    class="animated-lines"
                />
            </g>
            <g>
                <polyline
                    v-for="index in 6"
                    :key="index"
                    vector-effect="non-scaling-stroke"
                    stroke-opacity="0.5"
                    stroke-width="1"
                    class="animated-lines2"
                />
            </g>
        </svg>
    </div>
</template>
<style>
.wave {
    stroke: url(#gradient);
    z-index: -1;
}
.wave polyline {
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}
</style>
<script setup>
import gsap from "gsap";
import { onMounted, onBeforeMount, onUnmounted, ref } from "vue";

const svg = ref(null);

onBeforeMount(() => {
    window.addEventListener("resize", onResize);
});

onUnmounted(() => {
    window.removeEventListener("resize", onResize);
});

onMounted(() => {
    onInit();
});

const onResize = () => {
    onInit();
};

const onInit = () => {
    var width = window.innerWidth + 20;

    gsap.defaults({
        ease: "sine.inOut",
    });

    const dashed_waves = svg.value.querySelectorAll(".animated-dashed-lines");
    const waves = svg.value.querySelectorAll(".animated-lines");
    const waves2 = svg.value.querySelectorAll(".animated-lines2");

    dashed_waves.forEach((item, n) => {
        item.points.clear();
    });

    waves.forEach((item, n) => {
        item.points.clear();
    });

    waves2.forEach((item, n) => {
        item.points.clear();
    });

    var amplitude = parseInt(width / 35);
    var frequency = 2;
    var segments = parseInt(width / 20);
    var interval = parseInt(width / segments);

    dashed_waves.forEach((item, n) => {
        for (var i = 0; i <= segments; i++) {
            var norm = i / segments;
            var point = item.points.appendItem(svg.value.createSVGPoint());
            point.x = i * interval;
            point.y = amplitude / (0.3 * (n + 1));

            gsap.to(point, 4, {
                y: -point.y,
                repeat: -1,
                yoyo: true,
            }).progress(norm * frequency);
        }
    });

    waves.forEach((item, n) => {
        gsap.set(item, { y: 5 * n });

        for (var i = 0; i <= segments; i++) {
            var norm = (i + n) / segments;
            var point = item.points.appendItem(svg.value.createSVGPoint());
            point.x = i * interval;
            point.y = (amplitude / 0.4) * norm;

            gsap.to(point, 22, {
                y: -point.y,
                repeat: -1,
                yoyo: true,
            }).progress(norm * 3);
        }
    });

    waves2.forEach((item, n) => {
        gsap.set(item, { y: -5 * n });

        for (var i = 0; i <= segments; i++) {
            var norm = (i + n) / segments;
            var point = item.points.appendItem(svg.value.createSVGPoint());
            point.x = i * interval;
            point.y = (amplitude / 0.6) * norm;

            gsap.to(point, 6, {
                y: -point.y,
                repeat: -1,
                yoyo: true,
            }).progress(norm * 3);
        }
    });
};
</script>
