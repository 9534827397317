<template>
    <Field
        v-slot="{ field, errors, value, resetField, handleChange, validate }"
        :label="control.label"
        :name="control.name"
        :rules="control.rules"
    >
        <div :class="control.class">
            <label
                v-if="control.label"
                :for="control.uuid"
                class="label"
                :class="{
                    required:
                        control.rules &&
                        control.rules.split('|').indexOf('required') != -1,
                }"
            >
                {{ control.label }}
            </label>
            <label
                tabindex="0"
                :class="[
                    `w-auto max-w-64 text-gray-400 py-3 px-4 border border-white/10 bg-white/5 rounded-lg block transition duration-200 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-primary-500`,
                ]"
            >
                <span class="pointer-events-none">{{
                    control.placeholder
                }}</span>
                <input
                    :id="control.uuid"
                    type="file"
                    :multiple="control.multiple || false"
                    :readonly="control.readonly"
                    :disabled="control.disabled"
                    class="hidden"
                    :accept="control.accept"
                    @change="
                        handleChange($event, validate);
                        onFileChange($event);
                    "
                />
            </label>
            <div v-if="value" class="my-2">
                <div
                    v-if="control.multiple"
                    class="flex justify-between py-1 block transition duration-200 ease-in-out focus:ring-2 focus:ring-gray-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-gray-500 w-full"
                    :class="{ 'is-invalid': !!errors.length }"
                    v-for="file in value"
                    :key="file.name"
                >
                    <div class="flex-grow-0 truncate">
                        {{ file.name }} - {{ $filters.prettyBytes(file.size) }}
                    </div>
                    <!--span class="cursor-pointer p-1" @click="value = ''">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </span-->
                </div>
                <div
                    v-else
                    class="flex justify-between py-1 block transition duration-200 ease-in-out focus:ring-2 focus:ring-gray-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-gray-500 w-full"
                    :class="{ 'is-invalid': !!errors.length }"
                >
                    <div class="flex-grow-0 truncate">
                        {{ value.name }} -
                        {{ $filters.prettyBytes(value.size) }}
                    </div>
                    <!--span class="cursor-pointer p-1" @click="value = ''">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </span-->
                </div>
            </div>
            <ErrorMessage
                :name="control.name"
                class="block text-primary-600 text-sm mt-2"
            />
            <p v-if="control.note" class="text-sm text-gray-500 mt-2">
                {{ control.note }}
            </p>
        </div>
    </Field>
</template>
<script>
import { Field, ErrorMessage } from "vee-validate";
export default {
    name: "FileControl",
    emits: ["update:modelValue"],
    components: {
        Field,
        ErrorMessage,
    },
    props: {
        modelValue: {
            type: [File, FileList],
            required: false,
            default: null,
        },
        control: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        function onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            emit(
                "update:modelValue",
                props.control.multiple ? files : files[0],
            );
        }

        return {
            onFileChange,
        };
    },
};
</script>
