<template>
  <Field
    v-slot="{ field, errors, value }"
    :label="control.label"
    :name="control.name"
    :rules="control.rules"
  >
    <div :class="control.class">
      <label
        v-if="control.label"
        :for="control.uuid"
        class="label"
        :class="{'required': control.rules && control.rules.split('|').indexOf('required') != -1 }"
      >
        {{ control.label }}
      </label>
      <div class="relative flex items-start gap-8 py-2.5">
        <p
          v-if="control.placeholder"
          class="w-full"
        >{{ control.placeholder }}</p>
        <div class="inline-flex items-center gap-2">
          <span >{{ modelValue ? 'Yes' : 'No' }}</span>
          <label :for="control.uuid" class="relative flex cursor-pointer" :aria-label="control.placeholder">
            <input
              :id="control.uuid"
              :name="control.name"
              :disabled="control.disabled"
              ref="checkbox"
              type="checkbox"
              :checked="modelValue"
              class="sr-only peer"
              @change="changed"
            />
            <div class="w-11 h-6 border border-gray-300 bg-gray-200 transition peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray-500 peer-focus:ring-opacity-25 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-500 peer-checked:border-primary-500"></div>
          </label>
        </div>
      </div>
      <ErrorMessage
        :name="control.name"
        class="block text-primary-600 text-sm mt-2"
      />
      <p
        v-if="control.note"
        class="text-sm text-gray-500 mt-2"
      >{{ control.note }}</p>
    </div>
  </Field>
</template>
<script>
import { Field, ErrorMessage } from 'vee-validate'
export default {
  name: 'SwitchControl',
  emits: ['update:modelValue'],
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    modelValue: {
      type: [Boolean, String, Number],
      default: null
    },
    control: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    function changed(e) {
      emit('update:modelValue', e.currentTarget.checked);

    }

    return {
      changed
    }
  }
}
</script>
