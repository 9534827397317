<script setup lang="ts">
import { Field, ErrorMessage, useForm} from 'vee-validate';
import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

import { inject, ref } from 'vue';

const props =  defineProps({
  portal: {
    type: String,
    required: true
  },
  form: {
    type: String,
    required: true
  }
})

const { values, errors, meta, resetForm, setValues, setFieldValue } = useForm({
  keepValuesOnUnmount: true,
});
const currentStepIdx = ref(1);
const backStepIdx = ref(0);


const goNext = () => {

    backStepIdx.value = currentStepIdx.value
    window.lenis.scrollTo(document.getElementById(props.form), { offset: -160})
    if (currentStepIdx.value === 3 && values.bays < 5) {
      currentStepIdx.value = 5;
      return;
    }

    if (currentStepIdx.value === 4 && values.employees === 'No') {
      setFieldValue('status', 'fail')
      currentStepIdx.value = questions.length;

      questions[currentStepIdx.value-1].tagline = 'You Have Not Been Successful'
      questions[currentStepIdx.value-1].title = null
      questions[currentStepIdx.value-1].description = 'The EV Infrastructure Grant is for small to medium sized businesses. Contact Weev for assistance with your EV fleet development.'

      return;
    }


    if (currentStepIdx.value === 5 && values.has_companies_house_number === 'No') {
      setFieldValue('status', 'fail')
      currentStepIdx.value = questions.length;
      questions[currentStepIdx.value-1].tagline = 'You Have Not Been Successful'
      questions[currentStepIdx.value-1].title = null
      questions[currentStepIdx.value-1].description = 'Your business must have a Companies House number and/or be VAT registered to be eligible for the EV Infrastructure Grant. Contact Weev for assistance with your EV fleet development.'

      return;
    }

    if (currentStepIdx.value === 6 && values.deminimus === 'Yes') {
      setFieldValue('status', 'fail')
      currentStepIdx.value = questions.length;
      questions[currentStepIdx.value-1].tagline = 'You Have Not Been Successful'
      questions[currentStepIdx.value-1].title = null
      questions[currentStepIdx.value-1].description = 'If your business has received over £315,000 of grants in the past 3 years it is not eligible for the EV Infrastructure grant. Contact Weev for assistance with your EV fleet development.'

      return;
    }

    if (currentStepIdx.value === 7 && values.property_in_uk === 'No') {
      setFieldValue('status', 'fail')
      currentStepIdx.value = questions.length;
      questions[currentStepIdx.value-1].tagline = 'You Have Not Been Successful'
      questions[currentStepIdx.value-1].title = null
      questions[currentStepIdx.value-1].description = 'The sites for the EV infrastructure must be in the UK (excluding Channel Islands or Isle of Man). Contact Weev for assistance with your EV fleet development.'

      return;
    }

    if (currentStepIdx.value === 8 && values.private === 'No') {
      setFieldValue('status', 'fail')
      currentStepIdx.value = questions.length;
      questions[currentStepIdx.value-1].tagline = 'You Have Not Been Successful'
      questions[currentStepIdx.value-1].title = null
      questions[currentStepIdx.value-1].description = 'The sites for the EV infrastructure provided for must be for private use. Contact Weev for assistance with your EV fleet development.'

      return;
    }

    if (currentStepIdx.value === 9 && values.parking === 'No') {
      setFieldValue('status', 'fail')
      currentStepIdx.value = questions.length;
      questions[currentStepIdx.value-1].tagline = 'You Have Not Been Successful'
      questions[currentStepIdx.value-1].title = null
      questions[currentStepIdx.value-1].description = 'The EV parking bays must be off street, private, owned by you or have the legal right to use it. Contact Weev for assistance with your EV fleet development.'

      return;
    }

    if (currentStepIdx.value === 9 && values.parking === 'Yes') {
      setFieldValue('status', 'success')
      currentStepIdx.value = questions.length;
      questions[currentStepIdx.value-1].tagline = 'You Have Been Successful'
      questions[currentStepIdx.value-1].title = 'The information that you have provided suggests that you would be eligible for grant assistance.'
      questions[currentStepIdx.value-1].description = 'To receive your estimated calculation and to receive information from Weev on next steps please provide your email address below:'

      return;
    }

    currentStepIdx.value++;
    return;

};

const goBack = () => {
  if (currentStepIdx.value === 1) {
    return;
  }
  window.lenis.scrollTo(document.getElementById(props.form), { offset: -160})
  setFieldValue('status', false)
  currentStepIdx.value = backStepIdx.value;
  backStepIdx.value--;
}


const questions = [
  {
    id: 1,
    tagline: null,
    title: null,
    description: null,
    fields: [],
    info: `<div class="uppercase text-sm font-medium tracking-wide text-primary-400">
      Grant Information
    </div>
    <div class="grid lg:grid-cols-2 gap-4 lg:gap-16 mt-4">
      <div>
        <p class="text-lg md:text-xl lg:text-2xl font-medium max-w-2xl mb-4">
          1. Electric vehicle infrastructure grant for staff and fleets
        </p>
        <div class="prose lg:prose-lg max-w-3xl text-white/75">
          <p>The EV infrastructure grant for staff and fleets is only available to small and medium-sized businesses. The EV infrastructure grant helps out with any costs to do with building and installation work that&rsquo;s needed to install multiple chargepoint sockets, such as the installation of wiring and posts.</p>
          <p>With this grant, you can get:</p>
          <ul>
            <li>75% of the cost of the work paid for (up to a max of &pound;15,000)</li>
            <li>Up to &pound;350 per chargepoint socket installed</li>
            <li>Up to &pound;500 per parking space enabled with supporting infrastructure</li>
            <li>Up to five grants across five different sites</li>
          </ul>
        </div>
      </div>
      <div>
        <p class="text-lg md:text-xl lg:text-2xl font-medium max-w-2xl mb-4">
          2. The Workplace charging scheme grant
        </p>
        <div class="prose lg:prose-lg max-w-3xl text-white/75">

          <p>This grant can also be used at the same time as the EV infrastructure grant for staff and fleets. <strong>Both grants can be used for the same site, but never the same chargepoints.</strong></p>
          <p>The workplace charging scheme includes:</p>
          <ul>
            <li>Cover of up to 75% of the total costs of purchasing and installation of chargepoints</li>
            <li>Up to &pound;350 per socket</li>
            <li>Up to 40 sockets across all sites per applicant</li>
          </ul>
          <p>You can find more information about the WCS on the Government website.</p>
        </div>
      </div>
    </div>`
  },
  {
    id: 2,
    tagline: 'Sockets + Bays',
    title: 'How many EV Charging sockets do you want to install?',
    description: null,
    fields: [
      {
        component: 'InputControl',
        type: 'number',
        label: 'Sockets',
        name: 'sockets',
        placeholder: 'Enter number here',
        rules: 'required|numeric',
        class: 'col-span-6'
      }
    ],
  },
  {
    id: 3,
    tagline: 'Sockets + Bays',
    title: 'How many EV Charging Bays do you want to install?',
    description: 'To be eligible for the EV Infrastructure Grant you must provide for a minimum of 5 bays. If you are planning for less than 5 bays you may still be eligible for the Workplace Charging Scheme.',
    fields: [
      {
        component: 'InputControl',
        type: 'number',
        label: 'Bays',
        name: 'bays',
        placeholder: 'Enter number here',
        rules: 'required|numeric',
        class: 'col-span-6'
      }
    ],
  },
  {
    id: 4,
    tagline: 'Company Information',
    title: 'Does your company have less than 249 employees?',
    description: null,
    fields: [
      {
        component: 'RadioControl',
        name: 'employees',
        label: 'Answer',
        rules: 'required',
        class: 'col-span-6',
        options: [
          {
            label: 'Yes',
            value: 'Yes',
            icon: 'icon-yes'
          },
          {
            label: 'No',
            value: 'No',
            icon: 'icon-no'
          }
        ]
      }
    ],
  },
  {
    id: 5,
    tagline: 'Company Information',
    title: 'Do you have a Companies House number and/or VAT Registration number?',
    description: null,
    fields: [
      {
        component: 'RadioControl',
        name: 'has_companies_house_number',
        label: 'Answer',
        rules: 'required',
        class: 'col-span-6',
        options: [
          {
            label: 'Yes',
            value: 'Yes',
            icon: 'icon-yes'
          },
          {
            label: 'No',
            value: 'No',
            icon: 'icon-no'
          }
        ]
      },
    ],
  },

  {
    id: 6,
    tagline: 'Company Information',
    title: 'Have you had over £315,000 of grants in the past 3 years (Minimum Financial Assistance)?',
    fields: [
      {
        component: 'RadioControl',
        name: 'deminimus',
        label: 'Grants',
        rules: 'required',
        class: 'col-span-6',
        options: [
          {
            label: 'Yes',
            value: 'Yes',
            icon: 'icon-yes'
          },
          {
            label: 'No',
            value: 'No',
            icon: 'icon-no'
          },
          {
            label: 'Not Sure',
            value: 'Not Sure',
            icon: 'icon-ask'
          }
        ]
      },
    ],
  },

  {
    id: 7,
    tagline: 'Company Information',
    title: 'Is the property in the UK?',
    description: 'Not Channel Islands or the Isle of Man',
    fields: [
      {
        component: 'RadioControl',
        name: 'property_in_uk',
        rules: 'required',
        class: 'col-span-6',
        options: [
          {
            label: 'Yes',
            value: 'Yes',
            icon: 'icon-yes'
          },
          {
            label: 'No',
            value: 'No',
            icon: 'icon-no'
          }
        ]
      },
    ],
  },

  {
    id: 8,
    tagline: 'Company Information',
    title: 'Will the charge points be for private use only and not open to the public?',
    description: null,
    fields: [
      {
        component: 'RadioControl',
        name: 'private',
        rules: 'required',
        class: 'col-span-6',
        options: [
          {
            label: 'Yes',
            value: 'Yes',
            icon: 'icon-yes'
          },
          {
            label: 'No',
            value: 'No',
            icon: 'icon-no'
          }
        ]
      },
    ],
  },

  {
    id: 9,
    tagline: 'Company Information',
    title: 'Will the parking be off street, private, and is owned by you or have the legal right to use it?',
    description: null,
    fields: [
      {
        component: 'RadioControl',
        name: 'parking',
        rules: 'required',
        class: 'col-span-6',
        options: [
          {
            label: 'Yes',
            value: 'Yes',
            icon: 'icon-yes'
          },
          {
            label: 'No',
            value: 'No',
            icon: 'icon-no'
          }
        ]
      },
    ],
  },

  {
    id: 10,
    tagline: 'You Have Been Successful',
    title: 'The information that you have provided suggests that you would be eligible for grant assistance.',
    description: 'To receive your estimated calculation and to receive information from Weev on next steps please provide your email address below:',
    fields: [
      {
        component: 'InputControl',
        type: 'text',
        name: 'company',
        label: 'Company',
        placeholder: 'Company Name',
        rules: 'required',
        class: 'col-span-6'
      },
      {
        component: 'InputControl',
        type: 'text',
        name: 'firstname',
        label: 'First Name',
        placeholder: 'First Name',
        rules: 'required',
        class: 'col-span-6 lg:col-span-3'
      },
      {
        component: 'InputControl',
        type: 'text',
        name: 'lastname',
        label: 'Last Name',
        placeholder: 'Last Name',
        rules: 'required',
        class: 'col-span-6 lg:col-span-3'
      },
      {
        component: 'InputControl',
        type: 'email',
        name: 'email',
        label: 'Email',
        placeholder: 'Email address',
        rules: 'required|email',
        class: 'col-span-6 lg:col-span-3'
      },
      {
        component: 'InputControl',
        type: 'text',
        name: 'phone',
        label: 'Phone',
        placeholder: 'Contact number',
        rules: '',
        class: 'col-span-6 lg:col-span-3'
      },
    ],
  },


];

const Dialog = inject('Dialog')
const loading = ref(false)

const Submit = () => {

      loading.value = true

      setFieldValue('title', document.title)
      setFieldValue('page', window.location.href)
      setFieldValue('portal_id', props.portal)
      setFieldValue('form_id', props.form)


      axios.post('/api/ev-grant-eligibility/send', values)
          .then((response) => {
            resetForm();


            if(response.data.redirect){
              window.location = response.data.redirect
            }else{
              Dialog.alert({
                title: response.data.name,
                message: response.data.message,
              })
            }
          })
          .catch((err) => {
          //  actions.setErrors(err.response.data.errors)
            if(err.response.data.redirect){
              window.location = err.response.data.redirect
            }else{
              Dialog.alert({
                title: err.response.data.name,
                message: err.response.data.message,
              })
            }
          })
          .then(() => {
            loading.value = false
          })
}

</script>

<template>
  <div :id="form">
    <div class="w-full bg-white/5 h-1 rounded-lg overflow-hidden mb-8">
      <div
        class="bg-primary-500 h-1 transition-all duration-300"
        :style="{ 'width': `${((currentStepIdx-1)/questions.length)*100}%` }"
      ></div>
    </div>

    <template v-for="(question, index) in questions" :key="index">

      <div  v-if="currentStepIdx === question.id">

      <Transition
        enter-from-class="opacity-0 translate-y-0"
        enter-active-class="transition ease-in-out duration-300"
        enter-to-class="opacity-100 translate-y-0"
        leave-from-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in-out duration-0"
        leave-to-class="opacity-0 -translate-y-0"
      >
        <div v-show="currentStepIdx === question.id" class="space-y-4">



      <div v-if="question.tagline" class="uppercase text-sm font-medium tracking-wide text-primary-400">
        {{ question.tagline }}
      </div>
      <div v-if="question.title" class="text-lg md:text-xl lg:text-2xl font-medium max-w-2xl">
        {{ question.title }}
      </div>
      <div v-if="question.description" class="prose lg:prose-lg text-white/75 max-w-4xl">
        {{ question.description }}
      </div>

      <renderer v-if="question.fields.length" v-model="values" :fields="question.fields"></renderer>

      <div class="flex gap-4">
        <button
          v-if="question.id > 1"
          @click="goBack"
          type="button"
          class="border border-white/10 bg-white/5 py-2.5 px-6 font-medium text-sm disabled:opacity-50 disabled:cursor-not-allowed rounded"
        >
          Back
        </button>

        <button
          v-if="question.id > 1 && question.id < questions.length"
          :disabled="!meta.valid"
          class="bg-primary-500 border border-primary-500 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-primary-500 hover:text-white py-2.5 px-6 font-medium text-sm transition shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded"
          type="button"
          @click="goNext"
        >
          Continue
        </button>

        <button
          v-if="question.id === questions.length"
          :disabled="!meta.valid || loading"
          @click="Submit"
          class="relative bg-primary-500 border border-primary-500 disabled:opacity-50 disabled:cursor-not-allowed hover:bg-primary-500 hover:text-white py-2.5 px-6 font-medium text-sm transition shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded"
          type="button"
        >
          <span :class="{ 'invisible': loading }">Submit</span>
          <span v-if="loading" class="absolute inset-0 flex items-center justify-center">
            <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </span>
        </button>


        <button
          v-if="question.id === 1"
          class="bg-primary-500 border border-primary-500 disabled:bg-transparent hover:bg-primary-500 hover:text-white py-2.5 px-6 font-medium text-sm transition shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded"
          type="button"
          @click="goNext"
        >
          Start now
        </button>
      </div>

      <HtmlContent v-if="question.info" :control="{ content: question.info }" />

    </div>
    </Transition>



</div>
</template>
  </div>
</template>
