
import { defineRule } from 'vee-validate'
import { required, email, url, numeric, size, between, min, max} from '@vee-validate/rules'
/*
extend('postcode', {
  validate (value) {
    return new Promise((resolve) => {
      const pRegex = new RegExp('^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$')
      resolve({ valid: pRegex.test(value) })
    })
  },
  message: 'The {_field_} field is not a valid UK postcode'
})
*/



defineRule('between', between)
defineRule('required', required)
defineRule('numeric', numeric)
defineRule('email', email)
defineRule('url', url)
defineRule('size', size)
defineRule('max', max);

defineRule('min', min);

/*
defineRule('password_strength', (value, [], ctx) => {
  const { name } = ctx
  // Check if email
    if (!/^(?=.*?[A-Z]).*$/.test(value)) {
     return `${name} requires 1 uppercase letter.`
    }
    if (!/^(?=.*?[a-z]).*$/.test(value)) {
     return `${name} requires 1 lowercase letter.`
    }
    if (!/^(?=.*?[0-9]).*$/.test(value)) {
     return `${name} requires 1 number.`
    }
    if (!/^(?=.*?[#?!@$%^&*-]).*$/.test(value)) {
     return `${name} requires 1 special character.`
    }
    if (value.length < 8) {
      return `${name}  must be at least 8 characters.`
    }
    return true

});


defineRule('confirmed', (value, [target], ctx) => {
  if (value === ctx.form[target]) {
    return true
  }
  return 'Passwords must match'
});
*/
