import toast from "./toast.vue";
import service from "./service";
import { getCurrentInstance } from "vue"

toast.install = (app, options = {}) => {
  let plugin = service(options);
  app.config.globalProperties.$notify = plugin;
  app.provide('Notify', plugin)

};

export default toast;
export { toast, service };
