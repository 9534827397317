<template>
  <div :class="control.class">
    <label
      v-if="control.label"
      :for="control.uuid"
      class="label"
      :class="{'required': control.rules && control.rules.split('|').indexOf('required') != -1 }"
    >
      {{ control.label }}
    </label>
    <input
      v-model="value"
      :name="control.name"
      :id="control.uuid"
      :type="control.type"
      :maxlength="control.max"
      :minlength="control.min"
      :readonly="control.readonly"
      :placeholder="control.placeholder"
      :aria-label="control.placeholder"
      :disabled="control.disabled"
      autocomplete="off"
      class="appearance-none placeholder-gray-400 py-3 px-4 border border-white/10 bg-white/5 rounded-lg block transition duration-200 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-primary-500 w-full"
    />
    <Error v-if="errorMessage">{{ errorMessage }}</Error>
    <Note v-if="control.note">{{ control.note }}</Note>
  </div>
</template>
<script setup>
  import { useField } from 'vee-validate'
  const props = defineProps({
    control: Object
  })
  const { value, errorMessage } = useField(
    props.control.name,
    props.control.rules,
    {
      label: props.control.label
    }
  )
</script>
