<script setup>
import { h } from 'vue';

const props =  defineProps({
  class: {
    type: String,
    required: false
  },
  control: {
    type: Object,
    required: true
  }
})
const VNode = () => h('div', { class: props.class, innerHTML: props.control.content })
</script>
<template>
  <VNode />
</template>
