<script setup>
import { useForm, useIsFormValid } from "vee-validate";
import axios from "axios";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-CSRF-Token"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
import { inject, ref } from "vue";

const Dialog = inject("Dialog");

const props = defineProps({
    fields: {
        type: Array,
        required: true,
    },
    portal: {
        type: String,
        required: true,
    },
    form: {
        type: String,
        required: true,
    },
});

const form = ref({});
const loading = ref(false);

const { values, errors, resetForm, setFieldValue } = useForm();
const isValid = useIsFormValid();

const Submit = () => {
    loading.value = true;

    setFieldValue("title", document.title);
    setFieldValue("page", window.location.href);
    setFieldValue("portal_id", props.portal);
    setFieldValue("form_id", props.form);

    var formData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
    });

    axios
        .post("/api/promo/send", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            resetForm();

            if (response.data.redirect) {
                window.location = response.data.redirect;
            } else {
                Dialog.alert({
                    title: response.data.name,
                    message: response.data.message,
                });
            }
        })
        .catch((err) => {
            //  actions.setErrors(err.response.data.errors)
            if (err.response.data.redirect) {
                window.location = err.response.data.redirect;
            } else {
                Dialog.alert({
                    title: err.response.data.name,
                    message: err.response.data.message,
                });
            }
        })
        .then(() => {
            loading.value = false;
        });
};
</script>
<template>
    <div class="w-full">
        <slot />
        <form-renderer v-model="form" :fields="fields"></form-renderer>
        <div class="my-8 flex gap-4">
            <button
                :disabled="!isValid || loading"
                @click="Submit"
                class="uppercase font-medium bg-primary-500 border border-transparent inline-flex items-center justify-center flex-shrink-0 px-4 py-2 disabled:bg-primary-500/50 cursor-pointer disabled:cursor-not-allowed relative rounded"
            >
                <span :class="{ invisible: loading }">Submit</span>
                <span
                    v-if="loading"
                    class="absolute inset-0 flex items-center justify-center"
                >
                    <svg
                        class="animate-spin h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </span>
            </button>
        </div>
    </div>
</template>
