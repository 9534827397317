import toast from "./toast.vue";
import elements from "./elements.js";
import events from "./events";

const service = (globalOptions = {}) => {
  return {
    show(options = {}) {
      return elements.mountElement(toast, {
        props: { ...globalOptions, ...options },
      });
    },
    success(options = {}) {
      options.type = "success";
      return this.show(options);
    },
    error(options = {}) {
      options.type = "error";
      return this.show(options);
    },
    info(options = {}) {
      options.type = "info";
      return this.show(options);
    },
    warning(options = {}) {
      options.type = "warning";
      return this.show(options);
    },
    clear() {
      events.$emit("toast-clear");
    },
  };
};

export default service;
