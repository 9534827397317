<template>
  <div
    :class="style + shade + animation"
    @mouseover="toggleTimer(true)"
    @mouseleave="toggleTimer(false)"
    role="alert"
  >
    <button
      type="button"
      class="absolute right-2 top-2 w-8 h-8 flex justify-center items-center cursor-pointer"
      role="button"
      @click="click"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
    <div class="px-6 py-4 pr-10">
      <div v-if="title" class="font-medium mb-1">{{ title }}</div>
      <p class="text-sm">{{ message }}</p>
    </div>
    <div
      v-if="progressBar"
      class="absolute left-0 bottom-0 top-auto h-0 pt-1 bg-gray-900/20"
      :style="'width: ' + progress.percent + '%'"
    />
  </div>
</template>

<script>
import Timer from "./timer"
import events from "./events"
import elements from "./elements"

export default {
  name: "toast",
  props: {
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "default",
    },
    duration: {
      type: Number,
      default: 10000,
    },
    maxToasts: {
      type: [Number, Boolean],
      default: 5,
    },
    progressBar: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    parent: null,
    timer: null,
    queueTimer: null,
    transition: "toast-in",
    progress: {
      hideEta: 0,
      percent: 0,
      intervalId: null
    },
  }),
  computed: {
    animation() {
      return " animation-" + this.transition;
    },
    style() {
      return "relative rounded flex overflow-hidden shadow-md mb-4 pointer-events-auto max-w-sm w-full mx-auto";
    },
    shade() {
      switch (this.type) {
        case "info":
          return " bg-info-500 text-white";
        case "warning":
          return " bg-warning-500";
        case "success":
          return " bg-success-500";
        case "error":
          return " bg-danger-500 text-white";
        default:
          return " bg-white text-navy";
      }
    },
  },
  beforeMount() {
    this.createParent();
    this.setupContainer();
  },
  mounted() {
    this.notify();
    events.$on("toast-clean", this.close);
  },
  methods: {
    createParent() {
      this.parent = document.getElementById("_toast_container");
      if (!this.parent) {
        this.parent = document.createElement("div");
        this.parent.id = "_toast_container";
        this.parent.className =
          "fixed flex flex-col-reverse w-full h-full inset-0 p-6 z-50 pointer-events-none overflow-hidden";
      }
    },
    setupContainer() {
      const container = document.body;
      container.appendChild(this.parent);
    },
    shouldNotify() {
      if (this.maxToasts !== false) {
        return this.maxToasts <= this.parent.childElementCount;
      }

      return !this.maxToasts;
    },
    notify() {
      if (this.shouldNotify()) {
        this.queueTimer = setTimeout(this.notify, 250);
        return;
      }
      if (this.progressBar) {
          this.progress.hideEta = new Date().getTime() + parseFloat(this.duration)
          this.progress.intervalId = setInterval(() => this.refreshProgress(), 10)
      }
      this.parent.insertAdjacentElement("afterbegin", this.$el);
      this.timer = new Timer(this.close, this.duration);
    },
    click() {
      this.close();
    },
    toggleTimer(state) {
      if (this.timer) {
        state ? this.timer.pause() : this.timer.resume();
      }
    },
    refreshProgress () {
      this.progress.percent = ((this.progress.hideEta - (new Date().getTime())) / this.duration) * 100
    },
    close() {
      this.timer && this.timer.stop();
      clearTimeout(this.queueTimer);
      clearTimeout(this.progress.intervalId)
      this.transition = "toast-out";

      setTimeout(() => {
        elements.removeElement(this.$el);
      }, 250);
    },
  },
  beforeUnmount() {
    events.$off("toast-clear", this.close);
  },
};
</script>

<style>
.animation-toast-in {
  -webkit-animation: slide-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    forwards;
  animation: slide-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.animation-toast-out {
  -webkit-animation: fade-out 0.2s ease-out both;
  animation: fade-out 0.2s ease-out both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
