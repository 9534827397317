<template>
  <div :class="control.class">
    <label
      v-if="control.label"
      :for="control.uuid"
      class="label"
      :class="{'required': control.rules && control.rules.split('|').indexOf('required') != -1 }"
    >
      {{ control.label }}
    </label>
    <span class="relative block">
      <span class="absolute pointer-events-none right-0 inset-y-0 flex items-center justify-center px-4">
        <CalendarFold class="w-4 h-4 opacity-50"/>
      </span>
      <input
        v-model="value"
        :name="control.name"
        :id="control.uuid"
        type="date"
        :max="control.max"
        :min="control.min"
        :readonly="control.readonly"
        :placeholder="control.placeholder"
        :aria-label="control.placeholder"
        :disabled="control.disabled"
        autocomplete="off"
        class="appearance-none placeholder-gray-400 py-3 px-4 border border-white/10 bg-white/5 rounded-lg block transition duration-200 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-primary-500 w-full"
      />
    </span>
    <Error v-if="errorMessage">{{ errorMessage }}</Error>
    <Note v-if="control.note">{{ control.note }}</Note>
  </div>
</template>
<script setup>
  import { CalendarFold } from 'lucide-vue-next';
  import { useField } from 'vee-validate'
  const props = defineProps({
    control: Object
  })
  const { value, errorMessage } = useField(
    props.control.name,
    props.control.rules,
    {
      label: props.control.label
    }
  )
</script>
<style>

  input[type=date].appearance-none::-webkit-inner-spin-button,
  input[type=date].appearance-none::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=date].appearance-none {
    -moz-appearance:textfield;
  }
</style>
