<template>
  <div :class="control.class">
    <Label
      v-if="control.label"
      :text="control.label"
      :for="control.uuid"
      :required="control.rules && control.rules.split('|').indexOf('required') != -1"
    />
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">

          <label
          v-for="option in control.options" :key="option.value"
          class="relative flex flex-col border border-white/10 bg-white text-navy p-4 lg:p-8 rounded cursor-pointer hover:ring-2 hover:ring-white transition duration-200 ease-in-out"
          :class="{ 'ring-2 ring-primary-500' :  value  === option.value}"
          >
            <span class="block uppercase text-sm font-medium tracking-wide text-primary-500">{{ option.label }}</span>
            <span class="block">
              {{ option.subline }}
            </span>
            <span class="block text-lg md:text-xl font-medium">{{ option.headline }}</span>

            <Note v-if="option.note">{{ option.note }}</Note>
            <img
            :src="option.image" class="md:mx-auto w-full flex-grow-0 aspect-[3/4]"
            />

            <input
              v-model="value"
              type="radio"
              :name="control.name"
              :value="option.value"
              class="hidden h-5 w-5 text-primary-500 border-gray-300 transition duration-200 ease-in-out focus:ring-offset-0 focus:ring-2 focus:ring-gray-500 focus:outline-none focus:ring-opacity-25 disabled:opacity-50 disabled:cursor-not-allowed focus:border-gray-500"
            />

            <!-- Not Checked: "invisible" -->
            <div  v-if="option.value === value" class="absolute top-2 right-2 md:top-4 md:right-4 bg-primary-500 rounded-full h-6 w-6 p-1">
              <svg class="h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round"><path d="M20 6 9 17l-5-5"/></svg>
            </div>

            <span @click.stop.prevent="preview = option; $refs.ChargerModal.show()" class="cursor-pointer underline hover:no-underline text-primary-500">Product details</span>


          </label>
  
      </div>
      <Error v-if="errorMessage">{{ errorMessage }}</Error>
      <Note v-if="control.note">{{ control.note }}</Note>

          <!-- Add Block Modal -->
          <Modal ref="ChargerModal" >
            <div class="p-4 md:p-8">


              <div class="grid md:grid-cols-2 gap-4">
                <div>
                  <img :src="`${preview.image}?w=300&h=400&fm=webp`" class="max-w-full mx-auto" width="300" height="400" alt="">
                </div>
                <div>
                  <span class="uppercase text-sm font-medium tracking-wide text-primary-500 block">{{ preview.label }}</span>
                  <div class="text-lg md:text-xl font-medium">Tech features</div>
                  <div class="prose">
                    <HtmlContent :control="{ 'content': preview.content }" />
                  </div>
                </div>
              </div>
             </div>

          </Modal>
    </div>
</template>
<script setup>
  import { ref } from 'vue'
  import { useField } from 'vee-validate'
  const props = defineProps({
    control: Object
  })
  const preview = ref(null);
  const { value, errorMessage } = useField(
    props.control.name,
    props.control.rules,
    {
      label: props.control.label
    }
  )
</script>
