<template>
  <div v-if="fields" class="select-none grid grid-cols-6 gap-x-8 gap-y-4">
    <template v-for="control in fields" :key="control.uuid">
      <Component
        :is="control.component"
        v-if="control.if ? (modelValue[control.if.field] === control.if.value || false) : true"
        v-model="modelValue[control.name]"
        :control="{ uiid : generateUUID(), ...control}"
        :class="control.class"
        />
    </template>
  </div>
</template>
<script>
export default {
  name: 'FormRenderer',
  props: {
    modelValue: {
      type: Object,
      default: () => {},
      required: true
    },
    fields: {
      type: Array,
      required: true
    }
  },
  methods: {
    generateUUID () {
      let d = new Date().getTime()
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now()
      }
      const newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return newGuid
    }
  }
}
</script>
