<template>
  <Teleport to="body">
        <Transition
          :enter-from-class="classes?.overlayEnterClass"
          :enter-active-class="classes?.overlayEnterActiveClass"
          :enter-to-class="classes?.overlayEnterToClass"
          :leave-from-class="classes?.overlayLeaveClass"
          :leave-active-class="classes?.overlayLeaveActiveClass"
          :leave-to-class="classes?.overlayLeaveToClass"
        >
          <div v-if="open" @click="cancel()" class="z-40 h-screen w-screen inset-0 fixed bg-gray-900 bg-opacity-25 backdrop-blur-sm"></div>
        </Transition>
        <Transition
          :enter-from-class="classes?.enterClass"
          :enter-active-class="classes?.enterActiveClass"
          :enter-to-class="classes?.enterToClass"
          :leave-from-class="classes?.leaveClass"
          :leave-active-class="classes?.leaveActiveClass"
          :leave-to-class="classes?.leaveToClass"
        >
        <div v-if="open" @keyup.esc="cancel()" :class="classes?.wrapper">
          <div :class="classes?.modal">
                <div class="relative w-full">
                  <div class="p-6">
                    <div class="mb-6">
                      <div v-if="title" class="font-medium mb-2 text-lg">{{ title }}</div>
                      <div class="prose opacity-75"><p>{{ message }}</p></div>
                    </div>
                    <div class="w-full flex flex-col sm:flex-row sm:justify-strat gap-4">
                      <button
                        class="inline-flex items-center justify-center whitespace-nowrap rounded text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-black text-white shadow hover:bg-black/80 h-9 px-4 py-2"
                        @click="confirm"
                      >
                        {{ okButton }}
                      </button>
                      <button
                        v-if="type !== 'alert'"
                        class="inline-flex items-center justify-center whitespace-nowrap rounded text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-gray-100 shadow hover:bg-gray-50 h-9 px-4 py-2"
                        @click="cancel"
                      >
                        {{ cancelButton }}
                      </button>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </Transition>
  </Teleport>
</template>
<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
    variant: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: true,
      default: 'alert'
    },
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    okButton: {
     type: String,
     required: false,
     default: 'OK'
   },
   cancelButton: {
     type: String,
     required: false,
     default: 'Cancel'
   }
})

const classes = computed(() => {
  switch (props.variant) {
    case 'dark':
      return {
        wrapper: 'max-w-auto w-full lg:p-8 h-full flex items-center fixed inset-0 z-50',
        modal: ' z-50 relative w-full h-auto max-w-lg max-h-full mx-auto bg-gray-500/25 rounded text-white shadow-lg flex flex-col overflow-hidden',

        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'ease-out duration-300',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'ease-in duration-300',
        overlayLeaveToClass: 'opacity-0',

        enterClass: 'opacity-0 translate-y-8',
        enterActiveClass: 'ease-out duration-300',
        enterToClass: 'opacity-100 translate-y-0',
        leaveClass: 'opacity-100 translate-y-0' ,
        leaveActiveClass: 'ease-in duration-300',
        leaveToClass: 'opacity-0 translate-y-8'
      }
    default:
      return {
        wrapper: 'max-w-auto lg:p-8 h-full flex items-center fixed inset-0 z-50 mx-4',
        modal: ' z-50 relative w-full h-auto max-w-lg max-h-full mx-auto bg-white rounded text-black shadow-lg flex flex-col overflow-hidden',

        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'ease-out duration-300',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'ease-in duration-300',
        overlayLeaveToClass: 'opacity-0',

        enterClass: 'opacity-0 translate-y-8',
        enterActiveClass: 'ease-out duration-300',
        enterToClass: 'opacity-100 translate-y-0',
        leaveClass: 'opacity-100 translate-y-0' ,
        leaveActiveClass: 'ease-in duration-300',
        leaveToClass: 'opacity-0 translate-y-8'
      }
  }
})



const open = ref(false);

let confirmResolve = (value) => { /* do nothing */ }
let confirmReject = (value) => { /* do nothing */ }

const confirm = () => {
  confirmResolve(true);
  //window.lenis.start()
  document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
  open.value = false;
}

const cancel = () => {
  confirmResolve(false);
  //window.lenis.start()
  document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
  open.value = false;

}

const show = () => {
  open.value = true;
  document.getElementsByTagName('html')[0].classList.add('overflow-hidden')
  //window.lenis.stop()

  return new Promise((resolve) => {
      confirmResolve = resolve;
  });
}

defineExpose({
  show
})

</script>
